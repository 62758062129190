import styled from "@emotion/styled"
import React from "react"
import { Page } from "../components/page"

const Badge = styled.strong`
  display: inline-block;
  padding: 0.2em;
  font-size: 70%;
  color: #fff;
  background-color: #ffba0a;
  border-radius: 0.5em;
`

const ResearchRecruitmentPage = () => {
  return (
    <Page title="Research recruitment">
      <h2>
        Survey for women athletes in Olympic-style weightlifting{" "}
        <Badge>CLOSED</Badge>
      </h2>
    </Page>
  )
}

export default ResearchRecruitmentPage
